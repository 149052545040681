/* CUSTOM BUTTONS
 * http://charliepark.org/bootstrap_buttons/
 *******************************************/
.btn-teal {
  background-color: #24889e !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#50bed7", endColorstr="#23879d");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#50bed7), to(#23879d));
  background-image: -moz-linear-gradient(top, #50bed7, #23879d);
  background-image: -ms-linear-gradient(top, #50bed7, #23879d);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #50bed7), color-stop(100%, #23879d));
  background-image: -webkit-linear-gradient(top, #50bed7, #23879d);
  background-image: -o-linear-gradient(top, #50bed7, #23879d);
  background-image: linear-gradient(#50bed7, #23879d);
  border-color: #23879d #23879d #1f7689;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.33);
  -webkit-font-smoothing: antialiased;
}
.btn-blue {
  background-color: #134571 !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#227ac8", endColorstr="#134571");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#227ac8), to(#134571));
  background-image: -moz-linear-gradient(top, #227ac8, #134571);
  background-image: -ms-linear-gradient(top, #227ac8, #134571);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #227ac8), color-stop(100%, #134571));
  background-image: -webkit-linear-gradient(top, #227ac8, #134571);
  background-image: -o-linear-gradient(top, #227ac8, #134571);
  background-image: linear-gradient(#227ac8, #134571);
  border-color: #134571 #134571 #10385c;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.33);
  -webkit-font-smoothing: antialiased;
}
.btn-green {
  background-color: #a1c695 !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbe9d7", endColorstr="#a1c594");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#dbe9d7), to(#a1c594));
  background-image: -moz-linear-gradient(top, #dbe9d7, #a1c594);
  background-image: -ms-linear-gradient(top, #dbe9d7, #a1c594);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #dbe9d7), color-stop(100%, #a1c594));
  background-image: -webkit-linear-gradient(top, #dbe9d7, #a1c594);
  background-image: -o-linear-gradient(top, #dbe9d7, #a1c594);
  background-image: linear-gradient(#dbe9d7, #a1c594);
  border-color: #a1c594 #a1c594 #92bd84;
  color: #333 !important;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.33);
  -webkit-font-smoothing: antialiased;
}
.btn-brown {
  background-color: #55482a !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#927c48", endColorstr="#55482a");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#927c48), to(#55482a));
  background-image: -moz-linear-gradient(top, #927c48, #55482a);
  background-image: -ms-linear-gradient(top, #927c48, #55482a);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #927c48), color-stop(100%, #55482a));
  background-image: -webkit-linear-gradient(top, #927c48, #55482a);
  background-image: -o-linear-gradient(top, #927c48, #55482a);
  background-image: linear-gradient(#927c48, #55482a);
  border-color: #55482a #55482a #463b23;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.29);
  -webkit-font-smoothing: antialiased;
}
.btn-red {
  background-color: #5f1111 !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b42121", endColorstr="#5e1111");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#b42121), to(#5e1111));
  background-image: -moz-linear-gradient(top, #b42121, #5e1111);
  background-image: -ms-linear-gradient(top, #b42121, #5e1111);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b42121), color-stop(100%, #5e1111));
  background-image: -webkit-linear-gradient(top, #b42121, #5e1111);
  background-image: -o-linear-gradient(top, #b42121, #5e1111);
  background-image: linear-gradient(#b42121, #5e1111);
  border-color: #5e1111 #5e1111 #490d0d;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.33);
  -webkit-font-smoothing: antialiased;
}
.btn-black {
  background-color: #292929 !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b5b5b", endColorstr="#282828");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#5b5b5b), to(#282828));
  background-image: -moz-linear-gradient(top, #5b5b5b, #282828);
  background-image: -ms-linear-gradient(top, #5b5b5b, #282828);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5b5b5b), color-stop(100%, #282828));
  background-image: -webkit-linear-gradient(top, #5b5b5b, #282828);
  background-image: -o-linear-gradient(top, #5b5b5b, #282828);
  background-image: linear-gradient(#5b5b5b, #282828);
  border-color: #282828 #282828 #1c1c1c;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.33);
  -webkit-font-smoothing: antialiased;
}
.btn-orange {
  background-color: #cc7a00 !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffad32", endColorstr="#cc7a00");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#ffad32), to(#cc7a00));
  background-image: -moz-linear-gradient(top, #ffad32, #cc7a00);
  background-image: -ms-linear-gradient(top, #ffad32, #cc7a00);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffad32), color-stop(100%, #cc7a00));
  background-image: -webkit-linear-gradient(top, #ffad32, #cc7a00);
  background-image: -o-linear-gradient(top, #ffad32, #cc7a00);
  background-image: linear-gradient(#ffad32, #cc7a00);
  border-color: #cc7a00 #cc7a00 #b36b00;
  color: #333 !important;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.33);
  -webkit-font-smoothing: antialiased;
}
.file-input-wrapper.btn,
.btn-gray {
  background-image: linear-gradient(to bottom, #ffffff 0px, #dfdfdf 100%);
  background-repeat: repeat-x;
  border-color: #dfdfdf;
  color: black;
}
.modal-footer {
  background-color: #F5F5F5;
  padding: 14px 15px 15px;
}
.modal-footer .btn + .btn {
  margin: 5px;
}
.glyphicon {
  top: 2px;
}
/* FIX FORM CONTAINER FILE INPUT BUTTONS
********************************************/
