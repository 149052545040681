/*------------------------------------------------------------------------
# author    Eric Worman, HotRocket Development, Inc.
# copyright Copyright  2014. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website    Nua AIRS v2
# Path: /resources/assets/less/site.less
-------------------------------------------------------------------------*/

/* Import 3L mixins and apply reset.css
********************************************/
@import '3L/3L';

.reset();

@black: #333333;
@red: #d9534f;
@green: #216019;
@yellow: #F7F4CB;
@gray: #E0E0E0;
@orange: #f7941e;

@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);

/* Common
********************************************/
body {
  font-family: "Raleway", "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-weight:100;
  color:@black;
}

body.kr { font-family: 'Nanum Gothic', sans-serif; }

.pageWrapper { max-width:876px; width:95%; margin:auto; position:relative; }



/* Sidebar
********************************************/
.sidebar {
  padding-top:25px; float:left; width:225px;
}

.sidebarLogo { display:block; margin:0 auto 45px auto; }
.navHeader { padding:8px 0; font-weight:400; text-transform:uppercase; font-size:0.8em; }
.navLinks { max-height:1px; overflow:hidden; .transition(all, 1s); margin-left: 25px;  }
.navLinks > li { list-style: square inside none; padding:5px 0; font-size:0.8em; }
.navLinks > li > a { color:@black; }

.linkContainer:hover { cursor:pointer; }
.linkContainer:hover > .navLinks { max-height:200px;  }
.lowerSidebarLogo { margin-top:150px; }
.sidebarLowerLinks > li > a { font-size:0.7em; margin-left:40px; color:@black; }

.navLinksContainer .link.active, .navLinksContainer .link.active > a { color:@orange; }
.navLinks.active { max-height:200px; }


/* Header
********************************************/
.header {
  .navbar { height:51px; }
  .navbar-default { background-color:@orange; border:none; .border-radius(0px); }
  .navbar-default .navbar-nav > li > a { color:@black; text-transform:uppercase; padding:0px 15px; font-size:0.9em; }
  .navbar-default .navbar-nav > li { border-right:1px solid @black; margin-top:15px; }
  .navbar-default .navbar-nav > li:last-of-type { border:none; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover { background-color:transparent; color:white; }

  .navbar-header { height:auto; }

  .navbar-default .navbar-nav.languageSwitcher > li > a { font-size:0.8em; line-height:1em; text-transform:inherit; }
  .navbar-default .navbar-nav.socialLink > li > a { text-transform:inherit; }
  .navbar-default .navbar-nav.languageSwitcher > li { margin-top:19px; }

  .headerMenuItems { display:none; }

  .twitterLogo { height:20px; }

  .languageSwitcher .active a { color:white; }
}


/* Footer
********************************************/
.footer > .sidebarFooter { display:none; }
.footerText { font-size: 0.7em; margin:40px 0 0 245px; }



/* Content
********************************************/
.content {
  margin-left:245px;
}

.pageTitle { color:@orange; font-size: 38px; margin:15px 0 23px 0;  }
body.kr .pageTitle { font-size:30px; }
body.zh .pageTitle { font-size:30px; }
.bodyText { font-size:1.1em; line-height:1.3em; margin-bottom:10px; }
.heroImage { width:100%; display:block; margin:auto;  }


/* Homepage
********************************************/

.homepageContent { position:relative; }

div#animationDiv{
  position:absolute;
  display:none;
  top:80px;
  left:200px;
}
div#animationDiv div.square {
  position:absolute;
  background-color:white;
  -webkit-transition: background-color 2s linear;
  -moz-transition: background-color 2s linear;
  -o-transition: background-color 2s linear;
  -ms-transition: background-color 2s linear;
  transition: background-color 2s linear;

}


/* People
********************************************/

.bios { margin:55px 30px 0 0; }
.bioDetails { display:none; }
.bioDetails > div { margin-bottom:35px; }
.inlineBioPhoto { float:left; margin-right:10px; display:none; }

div#peoplePhotos{
  position:relative;
  margin-top:83px;
}
div#peoplePhotos > div{
  width:78px;
  height:79px;
  background-image:url("/images/combined_sprite.png");
  position:absolute;
}

div#bioTarget{
  width:180px;
  margin:60px;
}

p.bioName{
  color:#F7941E;
  font-size:14px;
  margin-bottom:10px;
  font-weight:400;
}
p.bioBody{
  font-size:12px;
}
p.bioBody_cn {
  font-size:13px;
  line-height:17px;
}
p.bioEmail a{
  font-size:12px;
  color:#F7941E;
}



/* Contact
********************************************/
.contactContent .pageTitle { margin:30px 0; }
.contactContent a { color:@black; }

table#contactUsTable{
  margin-left:30px;
  margin-top:20px;
  font-weight:100;
  font-size:17px;
}
table#contactUsTable td{
  padding-bottom:5px;
}

table#contactUsTable tr > td:first-child{
  color:#f7941e;
  padding-right:20px;
}
