/*------------------------------------------------------------------------
# author    Eric Worman, HotRocket Development, Inc.
# copyright Copyright  2014. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website    Nua AIRS v2
# Path: /resources/assets/less/site.less
-------------------------------------------------------------------------*/
/* Import 3L mixins
********************************************/
/* Disable zooming when input field gets focus
*********************************************/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: #eee;
  }
}
@media (max-width: 875px) {
  .socialLink {
    display: none;
  }
}
@media (max-width: 800px) {
  .header .navbar-default .navbar-nav > li > a {
    font-size: 0.7em;
  }
}
@media (max-width: 767px) {
  /* Common
  ********************************************/
  body {
    font-weight: normal !important;
  }
  /* Header
  ********************************************/
  .header .navbar-default .navbar-nav > .active > a,
  .header .navbar-default .navbar-nav > .active > a:focus,
  .header .navbar-default .navbar-nav > .active > a:hover {
    color: #f7941e;
  }
  .header .navbar-default .navbar-collapse .navbar-nav > li {
    border: none;
  }
  .header .navbar-default .navbar-collapse {
    padding-bottom: 25px;
  }
  .languageSwitcher {
    margin: 0 10px;
  }
  .navbar-nav.languageSwitcher > li {
    float: left;
  }
  .header .navbar-default .navbar-nav.languageSwitcher > li.active > a {
    color: white;
  }
  .navHeader {
    font-weight: 600;
  }
  .header .navbar-default .navbar-nav.mainNav > li > a {
    font-weight: 600;
    font-size: 0.8em;
    padding-left: 0;
  }
  .header .headerMenuItems {
    display: block;
    margin-top: 0;
  }
  .sidebar {
    width: 100%;
    float: none;
  }
  .sidebar .navLinksContainer {
    display: none;
  }
  .sidebarLogo {
    margin-bottom: 15px;
  }
  .navLinks {
    height: auto;
    max-height: 200px;
  }
  .navbar-default .navbar-collapse {
    background-color: white;
    position: relative;
    z-index: 100;
  }
  /* Main Content
  ********************************************/
  .content {
    margin: auto;
  }
  /* Sidebar
  ********************************************/
  .sidebarFooter {
    display: none;
  }
  .sidebar > .bios {
    display: none;
  }
  /* Footer
  ********************************************/
  .footer > .sidebarFooter {
    display: block;
  }
  .lowerSidebarLogo {
    margin: 5px;
  }
  .footerText {
    margin: 20px 10px 10px 10px;
    text-align: center;
  }
  /* Home
  ********************************************/
  .animationDiv {
    display: none !important;
  }
  /* People
  ********************************************/
  .peopleContent > .peoplePhotos {
    display: none;
  }
  .bioDetails {
    display: block;
  }
  .inlineBioPhoto {
    float: left;
    margin-right: 10px;
    display: inline;
  }
}
